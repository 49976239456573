import React, {useReducer} from 'react';
import {AuthContext} from './auth.context';
import jwt from 'jsonwebtoken';

const isBrowser = typeof window !== 'undefined';
const INITIAL_STATE = {
  isAuthenticated: isBrowser && !!localStorage.getItem('token'),
  user: isBrowser && !!localStorage.getItem('user') ? jwt.decode(localStorage.getItem("user")) : undefined,
  currentForm: 'signIn',
};

function reducer(state, action) {
  console.log(state, 'auth');

  switch (action.type) {
    case 'SIGNIN':
      return {
        ...state,
        currentForm: 'signIn',
      };
    case 'SIGNIN_SUCCESS':
      return {
        ...state,
        user: action.payload.user,
        isAuthenticated: true,
      };
    case 'SIGN_OUT':
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    case 'SIGNUP':
      return {
        ...state,
        currentForm: 'signUp',
      };
    case 'FORGOTPASS':
      return {
        ...state,
        currentForm: 'forgotPass',
      };
    case 'SIGNUP_SUCCESS':
      return {
        email: action.payload.email,
        password: action.payload.password
      }
    case 'LOGIN_SUCCESS':
      return {
        user: action.payload.user
      }
    default:
      return state;
  }
}

export const AuthProvider = ({children}) => {
  const [authState, authDispatch] = useReducer(reducer, INITIAL_STATE);

  const onSignupSuccess = (email, password) => {
    authDispatch({type: 'SIGNUP_SUCCESS', payload: {email, password}});
  }

  const onSignInSuccess = (token) => {
    console.log("jwt.decode(token) = ", jwt.decode(token));
    authDispatch({type: 'SIGNIN_SUCCESS', payload: {user: jwt.decode(token)}});
  }

  const onSignOut = () => {
    console.log("onSignOut called");
    authDispatch({type: 'SIGN_OUT'});
  }

  return (
    <AuthContext.Provider
      value={{
        authState,
        authDispatch,
        onSignupSuccess,
        onSignInSuccess,
        onSignOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
