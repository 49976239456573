import React, {Fragment, useEffect, useState} from 'react';
import _ from 'lodash';
import {Link, useHistory} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import PageHeader from '../../../components/common/page-header';
import Breadcrumb from '../../../components/common/breadcrumb';

import {useAppState} from "../../../contexts/app/app.provider";
import {AuthContext} from '../../../contexts/auth/auth.context';
import {GET_QUOTE_DETAILS} from "../../../graphql/query/quote.query";
import {withApolloClient} from "../../../components/withApolloClient";
import {getProductRedirectURLFromMeta} from "../../../services";
import {toast as Toast} from "react-toastify";
import CommentWidget from "../../../components/CommentSection";
import ProcessingLoader from "../../../components/loader/processing-loader";
import {APIFactory} from "../../../APIFactory";
import {downloadFileFromURL} from "../../../utils/Utils";
import uuid4 from "uuid4";

function QuotationDetail(props) {
  const companyName = useAppState('companyName');
  const pct = useAppState('pct');
  // const showPrice = pct && pct.productSettings && pct.productSettings.showPrice;
  const showPrice = true;
  // const showQuantity = pct && pct.productSettings && pct.productSettings.showQuantity;
  const showQuantity = true;
  const apiFactory = new APIFactory();

  const {authState: {isAuthenticated, user}} = React.useContext(AuthContext);
  let history = useHistory();

  // if (!isAuthenticated) {
  //   history.push(`${process.env.PUBLIC_URL}/login`);
  //   return;
  // }

  const {cartlist, total, removeFromCart, prevShip} = props;

  const [currencySymbol, setCurrencySymbol] = useState("");
  const [loading, setLoading] = useState(false);
  const [quotation, setQuotationData] = useState(null);
  const [templateData, setTemplateData] = useState(null);
  let quoteId = props.match.params.id;

  const getQuotationData = (networkCall, callback) => {
    const {client} = props;
    client.query({
      query: GET_QUOTE_DETAILS,
      variables: {
        quoteId: props.match.params.id,
      },
      ...(networkCall && {fetchPolicy: 'network-only'})
    }).then(data => {

      if (data && data.data && data.data.quote) {
        setLoading(false);
        let quote = data.data.quote;
        setQuotationData(quote);
        setCurrencySymbol(quote.currencySymbol);
        setTemplateData(quote.templateData && JSON.parse(quote.templateData) || null);
        callback && callback();
      } else {
        Toast.error("Quotation not found");
        history.push(`${process.env.PUBLIC_URL}/quotations`);
      }

    }).catch(err => {
      Toast.error("Quotation not found");
      history.push(`${process.env.PUBLIC_URL}/quotations`);
    });
  }

  useEffect(() => {
    getQuotationData(null, () => getQuotationData(true));
  }, [quoteId]);

  const exportToExcel = (e) => {
    e && e.preventDefault();

    Toast.success("Downloading...");

    apiFactory.generateExcel({
      type: "generateExcel",
      key: "uMkvMKema7XmKXHhT",
      mode: "quotation",
      quotationId: quotation._id
    }).then((data) => {
      if (data && data.data && data.data.url) {
        downloadFileFromURL(`${process.env.CLOUD_FRONT_URL}${data.data.url}`, _.last(_.split(data.data.url, "/")))
      }
    })
  }

  const uuid = user && user._id || uuid4();

  return (
    <>
      <Helmet>
        <title>{quotation && quotation.uniqueCode ? `Quotation #${quotation.uniqueCode}` : ""} - {companyName}</title>
      </Helmet>

      <h1 className="d-none">
        {quotation && quotation.uniqueCode ? `Quotation #${quotation.uniqueCode}` : ""} - {companyName}
      </h1>

      {
        quotation && <>
          <PageHeader title="Quotation Details" subTitle=""/>
          {/*{*/}
          {/*  isAuthenticated ? <Breadcrumb*/}
          {/*    title={`Quotation #${quotation.uniqueCode}`}*/}
          {/*    parent1={["Quotations", "quotations"]}*/}
          {/*  /> : <Breadcrumb*/}
          {/*    title={`Quotation #${quotation.uniqueCode}`}*/}
          {/*  />*/}
          {/*}*/}

          <Breadcrumb
            title={`Quotation #${quotation.uniqueCode}`}
          />

          <section className="shop shop-cart pt-0 pb-60">
            <div className="container">
              <div className="row">
                <div className={showPrice ? "col-lg-9" : "col-lg-12"}>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="cart-table table-responsive">
                        <table className="table">
                          <thead>
                          <tr className="cart-product">
                            {
                              templateData && templateData.headers ? templateData.headers.map((h, i) => {
                                if ((!showPrice && ["price", "total"].includes(h.type)) || (!showQuantity && ["quantity"].includes(h.type))) {
                                  return null;
                                } else {
                                  return <th
                                    className={`cart-product-${h.type === "thumb" ? "item" : h.type} ${["quantity", "price", "total"].includes(h.type) ? 'px-3 text-right' : ''}`}
                                    key={i}>
                                    {h.text}
                                  </th>
                                }
                              }) : null
                            }
                          </tr>
                          </thead>

                          <tbody>
                          {
                            quotation && quotation.raws && quotation.raws.length > 0 ? quotation.raws.map((row, index) => {
                              let item = quotation && quotation.products && quotation.products.find(p => p._id === row[0]);
                              return <tr key={'cart-item-' + index}>
                                {
                                  row.map((col, colIndex) => {
                                    if (colIndex === 1) {
                                      let productRedirectURL = getProductRedirectURLFromMeta(item.productMeta, item.productId);
                                      return <td className="quotation-product-item" key={colIndex}>
                                        <div className="quotation-product-img">
                                          <Link to={`${process.env.PUBLIC_URL}${productRedirectURL}`}>
                                            <img
                                              src={`${process.env.CLOUD_FRONT_URL}${item.thumb}`}
                                              title={item.name || "product"}
                                              alt={item.name || "product"}
                                            />
                                          </Link>
                                        </div>
                                      </td>
                                    } else if (colIndex === 0) {
                                      return <td className="price-col px-3 " key={colIndex}>
                                        {index + 1}
                                      </td>
                                    } else {
                                      if ((!showPrice && ["price", "total"].includes(templateData && templateData.headers[colIndex] && templateData.headers[colIndex].type)) || (!showQuantity && ["quantity"].includes(templateData && templateData.headers[colIndex] && templateData.headers[colIndex].type))) {
                                        return null;
                                      } else {
                                        return <td
                                          className={`${templateData && templateData.headers[colIndex].type !== 'name' ? 'text-right' : ''} px-3 ${templateData && templateData.headers[colIndex].type}-col`}
                                          key={colIndex}
                                        >
                                          {templateData && templateData.headers[colIndex].type && ["price", "total"].includes(templateData.headers[colIndex].type) && Number(col) ? currencySymbol : ""}
                                          {
                                            templateData && templateData.headers[colIndex].type && ["price", "total"].includes(templateData.headers[colIndex].type) ? (Number(col) || "").toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2
                                            }) : col
                                          }
                                        </td>
                                      }
                                    }
                                  })
                                }
                              </tr>
                            }) : <tr>
                              <td>
                                <p className="pl-2 pt-1 pb-1"> No Products in Quotation </p>
                              </td>
                            </tr>
                          }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  showPrice ? <aside className="col-lg-3">
                    <div className="summary summary-cart">
                      {
                        showPrice ? <Fragment>
                          <h6 className="summary-title">Summary</h6>

                          <table className="table table-summary">
                            <tbody>
                            <tr className="summary-subtotal">
                              <td>Subtotal:</td>
                              <td>
                                {currencySymbol}
                                {templateData && templateData.subTotal && (Number(templateData.subTotal) || "").toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })}
                              </td>
                            </tr>

                            <tr className="summary-total">
                              <td>Total:</td>
                              <td>
                                {currencySymbol}
                                {templateData && templateData.total && (Number(templateData.total) || "").toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })}
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </Fragment> : ''
                      }
                    </div>


                    <Link to="#" onClick={exportToExcel}>
                      {
                        loading ? <ProcessingLoader/> : <>
                          <i className="fa fa-download" style={{fontSize: '18px'}}/>
                          <span className="pl-2">Download Excel</span>
                        </>
                      }
                    </Link>

                    {/*<button*/}
                    {/*  className="btn btn--primary btn--rounded btn--block"*/}
                    {/*  onClick={exportToExcel}*/}
                    {/*  disabled={loading}*/}
                    {/*>*/}
                    {/*  {loading ? <ProcessingLoader/> : <span>Download Excel</span>}*/}
                    {/*</button>*/}

                  </aside> : null
                }
              </div>
            </div>
          </section>

          {
            <CommentWidget
              uuid={uuid}
              userName={quotation.createdByUserName}
              user={user}
              channel={quotation._id}
              pct={pct}
              companyId={pct._id}
            />
          }

        </>
      }

      {
        false && quotation && <div className="main">
          <PageHeader title="Quotation Details" subTitle=""/>
          <Breadcrumb title={`Quotation #${quotation.uniqueCode}`} parent1={["Quotations", "quotations"]}/>

          <div className="page-content">
            <div className="cart">
              <div className="container">
                <div className="row">
                  <div className={showPrice ? "col-lg-9" : "col-lg-12"}>
                    <table className="quotation-table table table-cart table-responsive">
                      <thead>
                      {/*<tr>*/}
                      {/*  <th>Product</th>*/}
                      {/*  {showPrice ? <th>Price</th> : null}*/}
                      {/*  <th>Quantity</th>*/}
                      {/*  {showPrice ? <th>Total</th> : null}*/}
                      {/*</tr>*/}
                      <tr>
                        {console.log('templateData.headers = ', templateData && templateData.headers)}
                        {
                          templateData && templateData.headers ? templateData.headers.map((h, i) => {
                            if (!showPrice && ["price", "total"].includes(h.type)) {
                              return null;
                            } else {
                              return <th
                                className={`${["quantity", "price", "total"].includes(h.type) ? 'px-3 text-right' : ''}`}
                                key={i}>
                                {h.text}
                              </th>
                            }
                          }) : null
                        }
                      </tr>
                      </thead>

                      <tbody>
                      {
                        quotation && quotation.raws && quotation.raws.length > 0 ? quotation.raws.map((row, index) => {
                          let item = quotation && quotation.products && quotation.products.find(p => p._id === row[0]);
                          return <tr key={'cart-item-' + index}>
                            {
                              row.map((col, colIndex) => {
                                if (colIndex === 1) {
                                  let productRedirectURL = getProductRedirectURLFromMeta(item.productMeta, item.productId);
                                  return <td className="product-col" key={colIndex}>
                                    <div className="product" key={colIndex}>
                                      <figure className="product-image-container product-media"
                                              style={{minWidth: '60px'}}>
                                        <Link to={`${process.env.PUBLIC_URL}${productRedirectURL}`}>
                                          {/*<img src={`${process.env.CLOUD_FRONT_URL}${item.thumb}`} alt={item.name}/>*/}
                                          {/*<LazyLoadImage*/}
                                          {/*  alt={item.name}*/}
                                          {/*  visibleByDefault={true}*/}
                                          {/*  src={`${process.env.CLOUD_FRONT_URL}${item.thumb}`}*/}
                                          {/*  threshold={100}*/}
                                          {/*/>*/}
                                          <div
                                            title={item.name || "product"}
                                            className="position-absolute h-100 w-100 bg-white"
                                            style={{
                                              backgroundImage: `url(${process.env.CLOUD_FRONT_URL}${item.thumb})`,
                                              backgroundSize: 'cover',
                                              backgroundPosition: 'center',
                                              top: 0,
                                              left: 0
                                            }}
                                          />
                                        </Link>
                                      </figure>

                                      {/*<h3 className="product-title d-flex row">*/}
                                      {/*  <span className="col-12">*/}
                                      {/*    <Link to={`${process.env.PUBLIC_URL}${productRedirectURL}`}>*/}
                                      {/*      {item.name}*/}
                                      {/*    </Link>*/}
                                      {/*  </span>*/}
                                      {/*  {*/}
                                      {/*    item.productMeta && item.productMeta.type && item.productMeta.type !== "product" ?*/}
                                      {/*      <small className="cart-product-info text-muted col-12">*/}
                                      {/*        in*/}
                                      {/*        {" "}*/}
                                      {/*        <Link*/}
                                      {/*          to={`${process.env.PUBLIC_URL}/${item.productMeta.type === "collection" ? "collections" : "showrooms"}/${item.productMeta.itemId}`}>*/}
                                      {/*          {item.productMeta.itemName}*/}
                                      {/*        </Link>*/}
                                      {/*      </small> : null*/}
                                      {/*  }*/}
                                      {/*</h3>*/}

                                    </div>
                                  </td>
                                } else if (colIndex === 0) {
                                  return <td className="price-col px-3 " key={colIndex}>
                                    {index + 1}
                                  </td>
                                } else {
                                  if (!showPrice && ["price", "total"].includes(templateData && templateData.headers[colIndex] && templateData.headers[colIndex].type)) {
                                    return null;
                                  } else {
                                    return <td
                                      className={` text-right px-3 ${templateData && templateData.headers[colIndex].type}-col`}
                                      key={colIndex}
                                    >
                                      {templateData && templateData.headers[colIndex].type && ["price", "total"].includes(templateData.headers[colIndex].type) && Number(col) ? currencySymbol : ""}
                                      {
                                        templateData && templateData.headers[colIndex].type && ["price", "total"].includes(templateData.headers[colIndex].type) ? (Number(col) || "").toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        }) : col
                                      }
                                    </td>
                                  }
                                }
                              })
                            }
                          </tr>
                        }) : <tr>
                          <td>
                            <p className="pl-2 pt-1 pb-1"> No Products in Quotation </p>
                          </td>
                        </tr>
                      }
                      </tbody>
                    </table>
                  </div>
                  {
                    showPrice ? <aside className="col-lg-3">
                      <div className="summary summary-cart">
                        {
                          showPrice ? <Fragment>
                            <h3 className="summary-title">Quotation Total</h3>

                            <table className="table table-summary">
                              <tbody>
                              <tr className="summary-subtotal">
                                <td>Subtotal:</td>
                                <td>
                                  {currencySymbol}
                                  {templateData && templateData.subTotal && (Number(templateData.subTotal) || "").toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })}
                                </td>
                              </tr>

                              <tr className="summary-total">
                                <td>Total:</td>
                                <td>
                                  {currencySymbol}
                                  {templateData && templateData.total && (Number(templateData.total) || "").toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })}
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </Fragment> : ''
                        }
                      </div>

                      <button
                        className="btn btn-outline-dark-2 btn-block mb-3"
                        onClick={exportToExcel}
                        disabled={loading}
                      >
                        {loading ? <ProcessingLoader/> : <span>Download Excel</span>}
                      </button>

                    </aside> : null
                  }
                </div>
              </div>
            </div>
          </div>

          {/*{*/}
          {/*  pct && pct.storeFrontSettings && pct.storeFrontSettings.subDomain === "testing" && <ChatWidget*/}
          {/*    uuid={user._id}*/}
          {/*    user={user}*/}
          {/*    channel={quotation._id}*/}
          {/*    pct={pct}*/}
          {/*    companyId={pct._id}*/}
          {/*  />*/}
          {/*}*/}

          {
            <CommentWidget
              uuid={user._id}
              user={user}
              channel={quotation._id}
              pct={pct}
              companyId={pct._id}
            />
          }

        </div>
      }
    </>
  )
}

export default withApolloClient(QuotationDetail);