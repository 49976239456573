import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from "@apollo/client";
import React from "react";
import {setContext} from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: process.env.API_URL,
});

const authLink = setContext((_, {headers}) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  const pct = localStorage.getItem('pct');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      pct: pct ? `${pct}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-first'
    }
  },
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          // Reusable helper function to generate a field
          // policy for the Query.products field.
          products: {
            keyArgs: ['type', 'category', 'materialCategory', 'text', 'search', 'price', 'sort', 'limit'],
            merge(existing, incoming) {
              const {items: newItems} = incoming;
              return existing ? {...incoming, items: [...existing.items, ...newItems],} : incoming;
            },
          },
          quotes: {
            keyArgs: [],
            merge(existing, incoming) {
              const {quotes: newItems} = incoming;
              return existing ? {...incoming, quotes: [...existing.quotes, ...newItems],} : incoming;
            },
          },
          showrooms: {
            keyArgs: ['search'],
            merge(existing, incoming) {
              const {items: newItems} = incoming;
              return existing ? {...incoming, items: [...existing.items, ...newItems],} : incoming;
            },
          },
          collections: {
            keyArgs: ['search'],
            merge(existing, incoming) {
              const {items: newItems} = incoming;
              return existing ? {...incoming, items: [...existing.items, ...newItems],} : incoming;
            },
          },
        },
      },
    },
  }),
});

function CustomApolloProvider(props) {
  return (
    <ApolloProvider client={client}>
      {props.children}
    </ApolloProvider>
  )
}

export default CustomApolloProvider;