import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
// Common Header Components
import {AuthContext} from '../../contexts/auth/auth.context';

import {showModal, toggleCategoryFilter} from '../../redux/actions';
import {useAppState} from "../../contexts/app/app.provider";
import {getCartCount, getWishlistCount} from "../../services";
import CartMenuSidebar from "./partials/cart-menu-sidebar";
import WishlistMenuSidebar from "./partials/wishlist-menu-sidebar";

function Header(props) {

  const companyName = useAppState('companyName');
  const pct = useAppState('pct');
  const appCategories = useAppState('categories');
  const appSubCategories = useAppState('subCategories');
  const socialLinks = useAppState('socialLinks');
  const careerLink = (socialLinks || []).find(sl => sl.name === "career");
  let showAbout = false;

  const [searchVisible, toggleSearchBarVisibility] = useState(false);
  const [cartVisible, toggleCartVisibility] = useState(false);
  const [wishlistVisible, toggleWishlistVisibility] = useState(false);
  const catImages = [
    "assets/images/paramount/menu/homedecore.png",
    "assets/images/paramount/menu/garden.png",
    "assets/images/paramount/menu/furniture.png",
    "assets/images/paramount/menu/tabletop.png",
  ];

  if (pct && pct.storeFrontSettings && pct.storeFrontSettings.subDomain && ["testing", "anuraj"].includes(pct.storeFrontSettings.subDomain)) {
    showAbout = true;
  }

  let history = useHistory();
  const [path, setPath] = useState("");
  const {
    authState: {isAuthenticated, user},
    authDispatch,
  } = React.useContext(AuthContext);

  useEffect(() => {
    setPath(window.location.pathname);
  });

  function onLogout(e) {
    e && e.preventDefault();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    authDispatch({type: 'SIGN_OUT'});
    history.push('/');
  }

  function openLoginModal(e) {
    props.showModal('login');
    e.preventDefault();
  }

  const isUserLoggedIn = !!isAuthenticated && !!user;
  const {wishlist, logo = "assets/images/logo.png", container} = props;

  return (
    <>

      <header id="navbar-spy1" className="header header-4 header-7 header-1 d-none d-lg-block">
        <nav id="primary-menu1" className="navbar navbar-expand-lg navbar-light">
          <div className="container">

            <Link className="navbar-brand" style={{display: 'flex', minWidth: 0}} to="/">
              <img className="logo logo-light home" src={`${process.env.CLOUD_FRONT_URL}${pct.logo}`}
                   alt={companyName}
              />
            </Link>

            <div className="collapse navbar-collapse" id="navbarContent1">
              <ul className="navbar-nav ml-auto mr-auto">

                <li
                  className={`has-dropdown mega-dropdown ${window.location.href.includes('products') ? 'active' : ''}`}>
                  <Link to="/products?category=all" className="menu-item">Products</Link>

                  <ul className="dropdown-menu mega-dropdown-menu">
                    <li>
                      <div className="container p-4">
                        <div className="row">

                          {
                            (appCategories || []).map((cat, index) => (
                              <div className="col-md-12 col-lg-4ths d-flex flex-column align-content-between"
                                   key={index}>
                                <div className="collection--menu-content flex-grow-1">
                                  <Link className={"p-0"} to={`/products?category=${cat.slug}`}>
                                    <h6>{cat.title}</h6>
                                  </Link>
                                  <ul>
                                    {
                                      ((appSubCategories || []).filter(c => c.parentCategoryId === cat._id)).map((sCat, sIndex) => (
                                        <li key={sIndex}>
                                          <Link to={`/products?category=${sCat.slug}`}>{sCat.title}</Link>
                                        </li>
                                      ))

                                    }
                                  </ul>
                                </div>
                              </div>
                            ))
                          }

                        </div>

                      </div>

                    </li>
                  </ul>
                </li>

                {/*<li*/}
                {/*  className={`has-dropdown mega-dropdown ${window.location.href.includes('products') ? 'active' : ''}`}>*/}
                {/*  <Link to="/products?category=all" className="menu-item">Products</Link>*/}
                {/*  <ul className="dropdown-menu mega-dropdown-menu collections-menu">*/}
                {/*    <li>*/}
                {/*      <div className="container">*/}
                {/*        <div className="row">*/}

                {/*          {*/}
                {/*            (appCategories || []).map((cat, index) => (*/}
                {/*              <div className="col-md-12 col-lg-4ths d-flex flex-column align-content-between"*/}
                {/*                   key={index}>*/}
                {/*                <div className="collection--menu-content flex-grow-1">*/}
                {/*                  <Link to={`/products?category=${cat.slug}`}><h5>{cat.title}</h5></Link>*/}
                {/*                  <ul>*/}
                {/*                    {*/}
                {/*                      ((appSubCategories || []).filter(c => c.parentCategoryId === cat._id)).map((sCat, sIndex) => (*/}
                {/*                        <li key={sIndex}>*/}
                {/*                          <Link to={`/products?category=${sCat.slug}`}>{sCat.title}</Link>*/}
                {/*                        </li>*/}
                {/*                      ))*/}

                {/*                    }*/}
                {/*                    /!*<li>*!/*/}
                {/*                    /!*  <a href="shop-layout-fullwidth.html">chair</a>*!/*/}
                {/*                    /!*</li>*!/*/}
                {/*                    /!*<li>*!/*/}
                {/*                    /!*  <a href="shop-layout-sidebar-left.html">sofa</a>*!/*/}
                {/*                    /!*</li>*!/*/}
                {/*                    /!*<li>*!/*/}
                {/*                    /!*  <a href="shop-4columns.html">table</a>*!/*/}
                {/*                    /!*</li>*!/*/}
                {/*                    /!*<li>*!/*/}
                {/*                    /!*  <a href="shop-3columns.html">bed</a>*!/*/}
                {/*                    /!*</li>*!/*/}
                {/*                  </ul>*/}
                {/*                </div>*/}
                {/*                /!*<div className="menu--img">*!/*/}
                {/*                /!*  <img src={catImages[index] || catImages[0]} alt="img" className="img-fluid"/>*!/*/}
                {/*                /!*</div>*!/*/}
                {/*              </div>*/}
                {/*            ))*/}
                {/*          }*/}

                {/*        </div>*/}

                {/*      </div>*/}

                {/*    </li>*/}
                {/*  </ul>*/}
                {/*</li>*/}

                <li className={`${window.location.href.includes('collections') ? 'active' : ''}`}>
                  <Link to="/collections"
                        className="menu-item">Collections</Link>
                </li>

                <li className={`${window.location.href.includes('showrooms') ? 'active' : ''}`}>
                  <Link to="/showrooms" className="link-hover"
                  >Showrooms</Link>
                </li>

                <li className={`${window.location.href.includes('about') ? 'active' : ''}`}>
                  <Link to="/about" className="menu-item">About</Link>
                </li>

                <li className={`${window.location.href.includes('contact') ? 'active' : ''}`}>
                  <Link to="/contact" className="menu-item">Contact</Link>
                </li>

              </ul>
            </div>

            {/*<Link className="navbar-brand" style={{display: 'flex', minWidth: 0}} to="/">*/}
            {/*  <img className="logo logo-light home" src={`${process.env.CLOUD_FRONT_URL}${pct.logo}`}*/}
            {/*       alt={companyName}*/}
            {/*  />*/}
            {/*</Link>*/}

            {/*<div className="collapse navbar-collapse" id="navbarContent2">*/}
            {/*  <ul className="navbar-nav mr-auto ml-auto">*/}

            {/*    <li className="">*/}
            {/*      <Link to="/about" className="menu-item">fAbout</Link>*/}
            {/*    </li>*/}

            {/*    <li className="">*/}
            {/*      <Link to="/contact" className="menu-item">Contact</Link>*/}
            {/*    </li>*/}

            {/*  </ul>*/}
            {/*</div>*/}

            <div className="pull-right">
              <div className="module-container">

                <div
                  className={`module module-cart module-cart-3 pull-left ${wishlistVisible ? 'module-active' : ''}`}>
                  <div
                    className="module-icon cart-icon"
                    onClick={() => {
                      toggleWishlistVisibility(!wishlistVisible);
                    }}
                  >
                    <i className="ti-heart"></i>
                    <span className="title">wishlist</span>
                    <label className="module-label">{getWishlistCount(props.wishlist)}</label>
                  </div>
                  <WishlistMenuSidebar
                    visible={wishlistVisible}
                    toggle={toggleWishlistVisibility}
                  />
                </div>

                <div className={`module module-cart module-cart-3 pull-left ${cartVisible ? 'module-active' : ''}`}>
                  <div className="module-icon cart-icon" onClick={() => {
                    toggleCartVisibility(!cartVisible)
                  }}>
                    <i className="icon-bag"></i>
                    <span className="title">shop cart</span>
                    <label className="module-label">{getCartCount(props.cartlist)}</label>
                  </div>
                  <CartMenuSidebar
                    cartVisible={cartVisible}
                    toggleCartVisibility={toggleCartVisibility}
                  />
                </div>

              </div>
            </div>
          </div>
        </nav>
      </header>

      <header id="navbar-spy" className="header header-1 header-7 d-block d-lg-none">
        <nav id="primary-menu" className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <Link className="navbar-brand" style={{display: 'flex', minWidth: 0}} to="/">
              <img className="logo logo-light home" src={`${process.env.CLOUD_FRONT_URL}${pct.logo}`}
                   alt={companyName}
              />
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent"
                    aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarContent">
              <ul className="navbar-nav mr-auto">

                <li className="has-dropdown mega-dropdown active">
                  <a href="#" data-toggle="dropdown" className="dropdown-toggle menu-item">Products</a>
                  <ul className="dropdown-menu mega-dropdown-menu">
                    <li>
                      <div className="container">
                        <div className="row">

                          {
                            (appCategories || []).map((cat, index) => (
                                <div className="col-md-12 col-lg-3" key={index}>
                                  <Link to={`/products?category=${cat.slug}`} className="border-0 p-0">
                                    <h6 className="mt-2 mb-0">{cat.title}</h6>
                                  </Link>
                                  <ul>
                                    {
                                      ((appSubCategories || []).filter(c => c.parentCategoryId === cat._id)).map((sCat, sIndex) => (
                                        <li key={sIndex}>
                                          <Link to={`/products?category=${sCat.slug}`}>{sCat.title}</Link>
                                        </li>
                                      ))

                                    }
                                  </ul>
                                </div>
                              )
                            )
                          }

                        </div>

                      </div>

                    </li>
                  </ul>

                </li>


                <li className="has-dropdown">
                  <Link to="/collections" className="link-hover"
                  >Collections</Link>
                </li>
                <li className="has-dropdown">
                  <Link to="/showrooms" className="link-hover"
                  >Showrooms</Link>
                </li>

                <li className="">
                  <Link to="/about" className="menu-item">About</Link>
                </li>

                <li className="">
                  <Link to="/contact" className="menu-item">Contact</Link>
                </li>
              </ul>
              <div className="module-container d-flex flex-row">
                <div
                  className={`module module-cart module-cart-3 pull-left ${wishlistVisible ? 'module-active' : ''}`}>
                  <div
                    className="module-icon cart-icon"
                    onClick={() => {
                      toggleWishlistVisibility(!wishlistVisible);
                    }}
                  >
                    <i className="ti-heart"></i>
                    <span className="title">wishlist</span>
                    <label className="module-label">{getWishlistCount(props.wishlist)}</label>
                  </div>
                  <WishlistMenuSidebar
                    visible={wishlistVisible}
                    toggle={toggleWishlistVisibility}
                  />
                </div>
                <div className={`module module-cart module-cart-3 pull-left ${cartVisible ? 'module-active' : ''}`}>
                  <div className="module-icon cart-icon" onClick={() => {
                    toggleCartVisibility(!cartVisible)
                  }}>
                    <i className="icon-bag"></i>
                    <span className="title">shop cart</span>
                    <label className="module-label">{getCartCount(props.cartlist)}</label>
                  </div>
                  <CartMenuSidebar
                    cartVisible={cartVisible}
                    toggleCartVisibility={toggleCartVisibility}
                  />
                </div>

              </div>
            </div>

          </div>

        </nav>
      </header>


    </>
  );
}

function mapStateToProps(state) {
  return {
    wishlist: state.wishlist.list ? state.wishlist.list : [],
    cartlist: state.cartlist.cart ? state.cartlist.cart : []
  }
}

export default connect(mapStateToProps, {showModal, toggleCategoryFilter})(Header);